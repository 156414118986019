import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=31288148&scoped=true&"
import script from "./Menu.vue?vue&type=script&lang=js&"
export * from "./Menu.vue?vue&type=script&lang=js&"
import style0 from "./Menu.vue?vue&type=style&index=0&id=31288148&prod&lang=scss&module=true&"
import style1 from "./Menu.vue?vue&type=style&index=1&id=31288148&prod&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "31288148",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSwitch: require('/codebuild/output/src3813353677/src/lusio/components/LanguageSwitch.vue').default})
